<script setup>

import { watch, reactive, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { i18n, PopUpManager as PopUp, STATE } from '@/Ship';
import { useUserActions, useUserComponents } from '@user';
import { useProfileActions } from '@profile';
import { useTeamActions } from '@team';
import { useSettingsResources } from "@settings";
import { useAuthComponents } from "@auth";

const PasswordInput = useAuthComponents('PasswordInput');

const {
    getUserResource,
    generateApiKey
} = useUserActions();
const user = getUserResource();

const getTeamResource = useTeamActions('getTeamResource');
const teams = getTeamResource();

const Whitelist = useUserComponents('Whitelist');

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const settings = useSettingsResources('Settings');
if (!settings.roles) {
    settings.load('roles');
}

const router = useRouter();
const route = useRoute();

const isRender = ref(true);
watch(() => user.index, () => {
    isRender.value = false;
    setTimeout(() => {
        isRender.value = true;
    }, 0);
});

const errors = reactive({
    name: false,
    email: false,
    password: false,
});
const isComplex = ref(false);

const statusList = [
    { name: 'Active' },
    { name: 'Suspended' },
    { name: 'Deleted' }
];

const payload = reactive({});

function setValue(key, value) {
    if (user.model[key] === value) {
        delete payload[key];
    } else {
        payload[key] = value;
    }
}


const name = computed({
    get: () => {
        return payload.name || user.model.name
    },
    set: (value) => {
        setValue('name', value);
        return true;
    }
});

const email = computed({
    get: () => payload.email || user.model.email,
    set: (value) => {
        setValue('email', value);
        return true;
    }
});

const status = computed({
    get: () => payload.status || user.model.status,
    set: (value) => {
        setValue('status', value);
        return true;
    }
});

const password = computed({
    get: () => payload.password || user.model.password,
    set: (value) => {
        setValue('password', value);
        return true;
    }
});

const userRoles = computed({
    get: () => payload.roles || (user.model.roles || []).map(team => team.id),
    set: (value) => {

        const roles = (user.model.roles || []).map(role => role.id);

        if (value.some(item => !roles.includes(item)) || roles.some(item => !value.includes(item))) {
            payload.roles = value;
        } else {
            delete payload.roles;
        }

        return true;
    }
});

const userTeams = computed({
    get: () => payload.teams || (user.model.teams || []).map(team => team.id),
    set: (value) => {

        const teams = (user.model.teams || []).map(team => team.id);

        if (value.some(item => !teams.includes(item)) || teams.some(item => !value.includes(item))) {
            payload.teams = value;
        } else {
            delete payload.teams;
        }

        return true;
    }
});

function save() {
    user.save(user.model.id, payload).then(() => {

        Object.keys(payload).forEach(key => {
            switch (key) {
                case 'teams':
                    user.state[user.index].teams = payload.teams.map(item => teams.state.find(team => team.id === item));
                    break;
                case 'roles':
                    user.state[user.index].roles = payload.roles.map(item => settings.roles.find(team => team.id === item));
                    break;
                default:
                    user.state[user.index][key] = payload[key];
                    break;
            }
            delete payload[key];
        });

        reset();
    });
}

function reset() {
    Object.keys(payload).forEach(key => delete payload[key]);
}

function deleteUser() {
    PopUp
        .setCaption(i18n.global.t('user.remove_user'))
        .setMessage(`<p class="message"><b>${i18n.global.t('user.remove_confirm', { id: user.model.id }, 'The User with ID:{id} will be permanently deleted')}!</b></p>`)
        .setAction(() => {
            user.delete(user.model.id).then(() => {
                user.state.splice(user.index, 1);
                user.index = null;
                router.push({ name: 'Users', params: route.params });
            });

            PopUp.close();
        })
        .open(require('@/Ship/components/PopUps/Confirm'));
}

function apiKeyGen() {
    generateApiKey(user.model.id).then(response => {
        user.state[user.index].auth_key = response.auth_key;
    });
}

function closeUser() {
    router.push({ name: 'Users', params: route.params });
}
</script>

<template>
    <div class="user-form">

        <g-preloader-overlay v-if="user.isLoading"/>

        <g-flex align="center" justify="between" class="user-bar">
            <router-link class="close-btn" v-bind:to="{ name: 'Users', params: $route.params }">
                <g-symbol name="close" width="24" height="24"/>
            </router-link>

            <g-caption size="4">ID: {{ user.model.id }}</g-caption>

            <g-button v-if="profile.can('User Remove')"
                      class="remove-btn with-tooltip"
                      type="button"
                      v-on:click="deleteUser"
                      v-bind:data-tooltip="$t('base.remove', 'Remove')">
                <g-symbol name="delete" width="18" height="18"/>
            </g-button>
        </g-flex>

        <div v-if="isRender" class="user custom-scrollbar">
            <g-flex class="container" justify="between" align="start" wrap>

                <fieldset>
                    <legend>{{ $t('user.username', 'Username') }}</legend>
                    <g-input v-model="name"
                             v-bind:backlight="!!payload.name"
                             v-bind:readonly="profile.cant('User Edit')"
                             v-bind:error="errors.name">
                        <g-symbol v-if="profile.cant('User Edit')" name="lock" width="18" height="18"/>
                    </g-input>
                </fieldset>

                <fieldset>
                    <legend>Email</legend>
                    <g-input v-model="email"
                             type="email"
                             v-bind:backlight="!!payload.email"
                             v-bind:reaonly="profile.cant('User Edit')"
                             v-bind:error="errors.email">
                        <g-symbol v-if="profile.cant('User Edit')" name="lock" width="18" height="18"/>
                    </g-input>
                </fieldset>

                <fieldset>
                    <legend>{{ $t('setting.status', 'Status') }}</legend>
                    <g-select v-model="status"
                              v-bind:backlight="!!payload.status"
                              v-bind:options="statusList"
                              option-text="name"
                              option-value="name"
                              v-bind:readonly="profile.cant('User Edit')"/>
                </fieldset>

                <fieldset>
                    <legend>{{ $t('user.password', 'Password') }}</legend>
                    <password-input v-model="password"
                                    v-bind:backlight="!!payload.password"
                                    v-bind:disabled="profile.cant('User Edit')"
                                    v-model:complex="isComplex"
                                    v-bind:error="errors.password"/>
                </fieldset>
            </g-flex>

            <div class="roles container" v-bind:class="{ backlight: !!payload.roles }">
                <g-caption size="4" weight="600">{{ $t('parts.roles', 'Roles') }}</g-caption>
                <div>
                    <g-checkbox v-for="role in settings.roles"
                                v-model="userRoles"
                                v-bind:value="role.id"
                                v-bind:disabled="profile.cant('User Edit')"
                                v-bind:key="`role-${role.id}`">
                        {{ role.name }}
                    </g-checkbox>
                </div>
            </div>

            <div class="teams container" v-bind:class="{ backlight: !!payload.teams }">
                <g-caption size="4" weight="600">{{ $t('parts.teams', 'Teams') }}</g-caption>
                <div>
                    <g-checkbox v-for="team in teams.state"
                                v-model="userTeams"
                                v-bind:value="team.id"
                                v-bind:disabled="profile.cant('User Edit')"
                                v-bind:key="`team-${team.id}`">
                        {{ team.name }}
                    </g-checkbox>
                </div>
            </div>

            <g-flex class="btn-bar container" justify="end" gap="20">
                <g-link-button type="button" v-on:click="reset('roles')" v-bind:disabled="!Object.keys(payload).length">
                    <g-symbol name="refresh" width="18" height="18"/>
                    {{ $t('base.reset', 'Reset') }}
                </g-link-button>
                <g-link-button type="button" v-on:click="save('roles')" v-bind:disabled="!Object.keys(payload).length">
                    <g-symbol name="save" width="18" height="18"/>
                    {{ $t('base.save', 'Save') }}
                </g-link-button>
            </g-flex>

            <whitelist class="container" v-bind:user-id="user.model.id"/>

            <div class="generate-key container">
                <g-caption size="4" weight="600">{{ $t('user.api_key') }}</g-caption>
                <g-flex align="center" justify="end" gap="10">
                    <div class="api-key">
                        <g-copy v-bind:text="user.model.auth_key"/>
                    </div>
                    <g-button class="generate-btn" v-on:click="apiKeyGen" v-bind:disabled="profile.cant('User Edit')">
                        <g-symbol name="refresh"
                                  width="20"
                                  height="20"/>
                    </g-button>
                </g-flex>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.user-form {
    overflow: auto;
}

.user-bar {
    padding: 0 10px;
    height: var(--bar-height, $bar-height);
    color: var(--env-text-color, $env-text-color);
    position: sticky;
    top: 0;

    & > .remove-btn {
        width: 35px;
        height: 35px;
        font-size: 0;
        border: none;
        position: relative;
        background-color: transparent;
        fill: var(--env-text-color, $env-text-color);

        &::after {
            left: 30%;
            transform: translateX(-50%);
            top: calc(100% + var(--tooltip-arrow-size, $tooltip-arrow-size));
        }

        &::before {
            transform: translateX(-50%);
            left: 50%;
            top: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size));
        }

        &:hover {
            fill: var(--btn-primary-color, $btn-primary-color);
            border-color: var(--danger, $danger);
            background-color: var(--danger, $danger);

            &::after {
                background-color: var(--danger, $danger);
            }

            &::before {
                border-bottom-color: var(--danger, $danger);
            }
        }
    }
}

.container {
    width: 560px;
    margin: 20px auto;
    color: var(--env-text-color, $env-text-color);

    & > fieldset {
        width: 48%;
        margin: 4px 0;
    }
}

.user {
    padding: 20px;
    overflow: auto;
    height: calc(100% - var(--bar-height, $bar-height));
}

.btn-bar {
    padding: 10px;
}

.generate-btn {
    width: 60px;
    padding: 0 20px;
    color: white !important;
    background-color: var(--save-btn-bg, $save-btn-bg);

    svg {
        fill: white;
    }
}

.remove-btn {
    width: 35px;
    height: 35px;
    color: white;
    margin: 0 2px;
    font-size: 0;
    background-color: transparent;
    fill: var(--main-text-color, $main-text-color);
    border: 1px solid var(--main-text-color, $main-text-color);
    position: relative;

    &::after {
        left: 50%;
        transform: translateX(-50%);
        top: calc(100% + var(--tooltip-arrow-size, $tooltip-arrow-size));
    }

    &::before {
        transform: translateX(-50%);
        left: 50%;
        top: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size));
    }

    &:hover {
        fill: var(--btn-primary-color, $btn-primary-color);
        border-color: var(--danger, $danger);
        background-color: var(--danger, $danger);

        &::after {
            background-color: var(--danger, $danger);
        }

        &::before {
            border-bottom-color: var(--danger, $danger);
        }
    }
}

.teams,
.roles,
.whitelist,
.generate-key {
    padding: 0 15px 15px;

    &:not(.backlight) {
        border: 1px solid var(--input-border, $input-border);
    }

    &.backlight {
        border: 1px solid var(--secondary, $secondary);

        & > .g-caption {
            color: var(--secondary, $secondary);
        }
    }

    & > .g-caption {
        margin-left: 5px;
    }

    & > div {
        width: 100%;
        column-count: 4;
        column-gap: 10px;

        & > .g-checkbox {
            width: 100%;
            padding: 5px 0;
            display: inline-block;
        }
    }
}

.generate-key {
    padding: 8px 15px 15px;
    border: 1px solid var(--separator-color, $separator-color);

    .api-key {
        flex-grow: 1;
        text-align: right;
        padding-right: 15px;
        height: var(--field-height, $field-height);
        line-height: var(--field-height, $field-height);
        border-radius: var(--field-rounding, $field-rounding);
        border: 1px solid var(--field-border, $field-border);
        background-color: var(--field-active-bg, $field-active-bg);
    }
}

.close-btn {
    fill: var(--env-text-color, $env-text-color);

    &:hover {
        fill: var(--env-title-color, $env-title-color);
    }
}
</style>