<script setup>

import { onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useProfileActions } from '@profile';
import { useCampaignActions } from '@campaign';
import { PopUpManager as PopUp } from "@/Ship";

const router = useRouter();
const route = useRoute();

const getProfileResource =  useProfileActions('getProfileResource');
const profile = getProfileResource();

const getCampaignResource =  useCampaignActions('getCampaignResource');
const campaigns = getCampaignResource();

function openCampaign(id) {
    campaigns.index = campaigns.state.findIndex(item => item.id === id);
    router.push({ name: 'Campaign', params: {...route.params, id} });
}

function sortBy(field) {
    if (campaigns.sortBy === field) {
        profile.state.campaign.sortDir = campaigns.sortDir === 'asc'
            ? 'desc'
            : 'asc';
    }
    profile.state.campaign.sortBy = field;
    profile.saveState();

    campaigns.sort(
        profile.state.campaign.sortBy,
        profile.state.campaign.sortDir
    );
    campaigns.load();
}

onMounted(() => {
    campaigns.setIncludes(['offer', 'buyer.teamlead', 'countries']);

    campaigns.sort(
        profile.state.campaign.sortBy,
        profile.state.campaign.sortDir
    );

    campaigns.load().then(() => {
        if (route.params.id) {
            openCampaign(Number(route.params.id));
        }
    });
});
</script>

<template>
    <div class="campaigns environment">

        <g-preloader-overlay v-if="campaigns.isLoading"/>

        <g-flex class="toolbar" gap="5" align="center" justify="end">
            <g-button class="tool"
                      v-on:click="PopUp.open(require('@campaign/components/PopUps/MakeOffer')).setCaption($t('campaign.make_offer', 'Make Offer'))"
                      v-bind:disabled="profile.cant('Offer Make')">
                <g-symbol name="rocket" width="24" height="24"/>
                <g-symbol name="plus" width="18" height="18"/>
                <!--{{ $t('campaign.make_offer', 'Make Offer') }}-->
            </g-button>
            <g-button class="tool"
                      v-on:click="PopUp.open(require('@campaign/components/PopUps/MakeCampaign')).setCaption($t('campaign.make_campaign', 'Make Campaign'))"
                      v-bind:disabled="profile.cant('Campaign Make')">
                <g-symbol name="offers" width="24" height="24"/>
                <g-symbol name="plus" width="18" height="18"/>
                <!--{{ $t('campaign.make_campaign', 'Make Campaign') }}-->
            </g-button>

            <g-button class="tool"
                      v-on:click="PopUp.open(require('@campaign/components/PopUps/MakeBuyer')).setCaption($t('campaign.make_buyer', 'Make Buyer'))"
                      v-bind:disabled="profile.cant('Buyer Make')">
                <g-symbol name="customer" width="24" height="24"/>
                <g-symbol name="plus" width="18" height="18"/>
                <!--{{ $t('campaign.make_buyer', 'Make Buyer') }}-->
            </g-button>
        </g-flex>

		<div class="index">

			<div class="row header">
				<div class="id title cell sortable" v-bind:class="{ [`sorted ${campaigns.sortDir}`]: campaigns.sortBy === 'id' }">
					<g-flex align="center" justify="center" gap="2" v-on:click="sortBy('id')">
						ID <g-symbol name="arrow-down" width="14" height="14"/>
					</g-flex>
				</div>

				<div class="name title cell sortable" v-bind:class="{ [`sorted ${campaigns.sortDir}`]: campaigns.sortBy === 'name' }">
					<g-flex align="center" gap="2" v-on:click="sortBy('name')">
                        {{ $t('campaign.campaign') }} <g-symbol name="arrow-down" width="14" height="14"/>
					</g-flex>
				</div>

				<div class="offer title cell sortable" v-bind:class="{ [`sorted ${campaigns.sortDir}`]: campaigns.sortBy === 'offer_id' }">
					<g-flex align="center" gap="2" v-on:click="sortBy('offer_id')">
                        {{ $t('campaign.offer') }} <g-symbol name="arrow-down" width="14" height="14"/>
					</g-flex>
				</div>
                <div class="source title cell">{{ $t('campaign.source') }}</div>
				<div class="affiliate title cell">{{ $t('campaign.affiliate') }}</div>
				<div class="buyer title cell">{{ $t('campaign.buyer') }}</div>
				<div class="geo title cell">{{ $t('campaign.geo') }}</div>

				<div class="language title cell sortable" v-bind:class="{ [`sorted ${campaigns.sortDir}`]: campaigns.sortBy === 'language' }">
					<g-flex align="center" gap="2" v-on:click="sortBy('language')">
                        {{ $t('base.language') }} <g-symbol name="arrow-down" width="14" height="14"/>
					</g-flex>
				</div>
				<div class="leads title cell">{{ $t('campaign.leads') }}</div>

				<div class="date title cell sortable" v-bind:class="{ [`sorted ${campaigns.sortDir}`]: campaigns.sortBy === 'created_at' }">
                    {{ $t('base.created_at') }} <g-symbol name="arrow-down" width="14" height="14"/>
                </div>
			</div>

			<div class="row"
			     v-for="campaign in campaigns.state"
			     v-bind:key="`campaign-${campaign.id}`"
			     v-on:click="openCampaign(campaign.id)"
                 :class="{'open': $route.params.id === campaign.id.toString()}">
				<div class="id cell" v-bind:class="{ sorted: campaigns.sortBy === 'id' }">
					{{ campaign.id }}
				</div>
				<div class="name cell" v-bind:class="{ sorted: campaigns.sortBy === 'name' }">
					{{ campaign.name }}
				</div>
				<div class="offer cell" v-bind:class="{ sorted: campaigns.sortBy === 'offer_id' }">
					{{ campaign.offer.name }}
				</div>
                <div class="source cell">{{ campaign.source }}</div>
				<div class="affiliate cell">{{ campaign.buyer.teamlead.name }}</div>
				<div class="buyer cell">{{ campaign.buyer.name }}</div>
				<div class="geo cell">{{ campaign.geo.join(', ') }}</div>
				<div class="language cell" v-bind:class="{ sorted: campaigns.sortBy === 'language' }">
					{{ campaign.language }}
				</div>
				<div class="leads cell">{{ campaign.leads_count }}</div>
				<div class="date cell" v-bind:class="{ sorted: campaigns.sortBy === 'created_ar' }">{{ campaign.created_at }}</div>
			</div>
            <div class="info-block" v-if="!campaigns.state.length && !campaigns.isLoading">
                {{ $t('base.nothing_found', 'Nothing found') }}
            </div>
		</div>
    </div>
</template>

<style lang="scss" scoped>
.environment {
    height: 100%;
    margin: 0 6px;
    padding: 8px 20px;
    position: relative;
    border-radius: 12px;
    font-size: 0.9rem;
    border: 3px solid var(--secondary, $secondary);
    background-color: var(--env-bg, $env-bg);
    color: var(--env-text-color, $env-text-color);
}

.nothing-found {
    font-size: 1.5rem;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.toolbar {
    padding: 12px 20px;

    .tool {
        cursor: pointer;
        position: relative;
        width: 42px;
        fill: #eeeeee;
        background-color: var(--primary, $primary);

        & > .g-symbol:last-child {
            fill: black;
            padding: 4px;
            right: 4px;
            bottom: 4px;
            border-radius: 50%;
            position: absolute;
            background-color: #ffffffdd;
        }

        &:hover {
            fill: white;
            background-color: var(--primary-hover, $primary-hover);

            & > .g-symbol:last-child {
                fill: white;
                background-color: black;
            }
        }
    }
}

.index {
    min-width: 100%;
    padding: 0 10px;
    margin-bottom: 20px;
}

.row {
	&:nth-child(even) {
		background-color: #00000011;
	}

	&:not(.header) {
		cursor: pointer;

		& > .cell {
			border: 0 solid transparent;
			border-width: 1px 0;
		}

		&:hover > .cell,
        &.open > .cell {
			border-color: var(--success, $success);
		}
	}
}

.cell {
	white-space: nowrap;

    &.title {
        &.sortable {
            .g-symbol {
                cursor: pointer;
                fill: var(--env-text-color, $env-text-color);
            }

            &.sorted {
                font-weight: bold;
                fill: var(--sorted-text-color, $sorted-text-color);

                &.desc {
                    .g-symbol {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    &.sorted {
        color: var(--sorted-text-color, $sorted-text-color);
        background-color: var(--sorted-bg, $sorted-bg);
    }

	&.id,
	&.leads,
	&.language,
	&.date {
		text-align: center;
	}

    &.geo {
        max-width: 196px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

@media (min-width: 1025px) {
	.index {
		display: table;

		.row {
			display: table-row;

			& > .cell {
				padding: 10px 8px;
				display: table-cell;
				vertical-align: middle;
			}
		}
	}
}

@media (max-width: 1024px) {
	.index {
		display: table;

		.row {
			display: table-row;

			& > .cell {
				padding: 8px 5px;
				display: table-cell;
				vertical-align: middle;
			}
		}
	}
}
</style>
