<script setup>

import { reactive, computed } from 'vue';
import { useCampaignActions } from '@campaign';
import { useProfileActions } from '@profile';

const getCampaignResource = useCampaignActions('getCampaignResource');
const campaigns = getCampaignResource();

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

if (!profile.state.campaign) {
    profile.state.campaign = {
        filters: {},
        sortBy: 'id',
        sortDir: 'desc',
        expanded: []
    };
} else if (!profile.state.campaign.expanded) {
    profile.state.campaign.expanded = [];
}

const filters = reactive({});

Object.assign(filters, profile.state.campaign.filters);
campaigns.setFilters(filters);

const isChanged = computed(() => {
    return !_.isEqual(filters, campaigns.filters);
});

function filtrate() {
    Object.keys(filters).forEach(key => {
        if (!Object.keys(filters[key]).length) {
            delete filters[key];
        }
    });
    campaigns.setFilters(filters);
    campaigns.load();

    profile.state.campaign.filters = filters;
    profile.saveState();
}

function resetFilters() {
    Object.keys(filters).forEach(key => {
        delete filters[key];
    });
    Object.assign(filters, campaigns.filters);
}

function getFilterValueByKey(filter, key) {
    if (campaigns.filterset[filter]) {
        const filterset = campaigns.filterset[filter].find(item => item.id === Number(key) || item.value === key);
        return filterset ? filterset.key || filterset.value : key;
    }
    return key;
}

function dropFilter(filter, value) {
    delete filters[filter][value];
    if (!Object.keys(filters[filter]).length) {
        delete filters[filter];
    }
    campaigns.dropFilterValue(filter, value);
    campaigns.setPage(1);
    campaigns.load();

    profile.state.campaign.filters = campaigns.filters;
    profile.saveState();
}

function resetAllFilters() {
    Object.keys(campaigns.filters).forEach(filter => {
        delete filters[filter];
        Object.keys(campaigns.filters[filter]).forEach(key => {
            campaigns.dropFilterValue(filter, key);
        })
    });

    campaigns.setFilters(campaigns.filters);
    campaigns.setPage(1);
    campaigns.load(null, {}, 'Reset Filters');

    profile.state.campaign.filters = {};
    profile.saveState();
}

function isExpanded(section) {
    return computed({
        get: () => (profile.state.campaign.expanded || []).includes(section),
        set: value => {
            value
                ? profile.state.campaign.expanded.push(section)
                : profile.state.campaign.expanded.splice(profile.state.campaign.expanded.indexOf(section), 1);
            profile.saveState();
            return true
        }
    })
}

const offersExt = isExpanded('offers');
const affiliatesExt = isExpanded('affiliates');
const buyersExt = isExpanded('buyers');
const countriesExt = isExpanded('countries');
const languageExt = isExpanded('language');
</script>

<template>

    <g-flex class="filters" gap="4" wrap="wrap" align="end">
        <fieldset v-for="(values, key) in campaigns.filters"
                 class="filter"
                 v-bind:class="key"
                 v-bind:key="key">
            <legend>{{ $t(`filters.${key}`, key) }}:</legend>

            <div class="filter-item" v-for="(value, i) in Object.keys(values)" v-bind:key="`${key}-${i}`">
                <div class="value">{{ getFilterValueByKey(key, value) }}</div>
                <g-symbol name="close" width="14" height="14" v-on:click="dropFilter(key, value)"/>
            </div>
        </fieldset>


        <fieldset v-if="Object.keys(campaigns.filters).length > 0" class="filter reset-all">
            <div class="filter-item"
                 v-on:click="resetAllFilters">
                <div class="value">Reset All</div>
                <g-symbol name="close" width="14" height="14"/>
            </div>
        </fieldset>
    </g-flex>

    <div>
        <g-filterset v-model="filters.offers"
                    v-model:expanded="offersExt"
                    v-bind:label="$t('filters.offers', 'Offers')"
                    value-field="id"
                    v-bind:filterset="campaigns.filterset.offers"/>

	    <g-filterset v-model="filters.language"
                    v-model:expanded="languageExt"
	                v-bind:label="$t('filters.languages', 'Languages')"
                    value-field="key"
	                v-bind:filterset="campaigns.filterset.language"/>

	    <g-filterset v-model="filters.buyers"
                    v-model:expanded="buyersExt"
	                v-bind:label="$t('filters.buyers', 'Buyers')"
	                value-field="id"
	                v-bind:filterset="campaigns.filterset.buyers"
                    is-search/>

	    <g-filterset v-model="filters.affiliates"
                    v-model:expanded="affiliatesExt"
	                v-bind:label="$t('filters.affiliates', 'Affiliates')"
	                value-field="id"
	                v-bind:filterset="campaigns.filterset.affiliates"
                    is-search/>

	    <g-filterset v-model="filters.countries"
                    v-model:expanded="countriesExt"
	                v-bind:label="$t('filters.geo', 'Geo')"
	                value-field="code"
	                v-bind:filterset="campaigns.filterset.countries"/>

        <transition>
            <g-flex v-if="isChanged" class="btn-bar" gap="5">
                <g-button class="reset-btn" v-on:click="resetFilters">Reset</g-button>
                <g-button class="apply-btn" v-on:click="filtrate">Apply</g-button>
            </g-flex>
        </transition>
    </div>
</template>

<style lang="scss" scoped>
.filters {
    padding: 10px 5px;
}

.filter {
    gap: 4px;
    flex-flow: wrap;
    padding: 4px 0;
    font-size: 0.8rem;
    border-radius: 5px;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    color: var(--sidebar-text-color, $sidebar-text-color);

    & > .filter-item {
        border-radius: 5px;
        padding: 0 4px 1px 8px;
        display: inline-flex;
        align-items: center;
        gap: 2px;
        height: 28px;
        color: white;
        box-shadow: 0 5px 15px -10px #000000aa;

        &:first-child {
            margin-left: 5px;
        }

        & > .g-symbol {
            cursor: pointer;
            fill: var(--btn-primary-color, $btn-primary-color);
        }

        & > .value {
            font-weight: 500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 56px;
        }
    }

    &.reset-all > .filter-item {
        background-color: black;
    }

    &.offers > .filter-item {
        background-color: #eaa308;
    }

    &.language > .filter-item {
        background-color: var(--primary-hover, $primary-hover);
    }

    &.buyers > .filter-item {
        background-color: var(--secondary-hover, $secondary-hover);
    }

    &.affiliates > .filter-item {
        background-color: #22a585;
    }

    &.countries > .filter-item {
        background-color: #b010df;
    }

    &:not(.countries, .affiliates, .buyers, .language, .offers, .reset-all) > .filter-item {
        background-color: var(--danger-hover, $danger-hover);
    }
}

.g-filterset {
    margin: 20px 0;
}

.btn-bar {
    margin: 0 -30px 0 -25px;
    text-align: right;
    padding: 8px 15px;
    bottom: 0;
    position: sticky;
    border-radius: 9px 9px 0 0;
    background-color: var(--env-bg, $env-bg);
    box-shadow: 0 -5px 15px -5px #00000033;

    & > .apply-btn,
    & > .reset-btn {
        width: 50%;
        flex-grow: 1;
        background-color: transparent;
        border: 1px solid var(--separator-color, $separator-color);
        color: var(--env-text-color, $env-text-color);

        &:hover {
            color: white;
            background-color: var(--primary-hover, $primary-hover);
        }
    }

    & > .reset-btn {
        background-color: transparent;
        border: 1px solid var(--separator-color, $separator-color);
        color: var(--env-text-color, $env-text-color);

        &:hover {
            color: white;
            background-color: var(--primary-hover, $primary-hover);
        }
    }

    & > .apply-btn {
        color: white;
        background-color: var(--primary, $primary);
        border: 1px solid var(--primary, $primary);

        &:hover {
            background-color: var(--primary-hover, $primary-hover);
        }
    }
}

.v-enter-active,
.v-leave-active {
    transition: bottom 0.4s;
}

.v-enter-from,
.v-leave-to {
    bottom: -100px;
}
</style>