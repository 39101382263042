<script setup>

import { computed, reactive, ref } from 'vue';
import { useFinanceActions } from '@finance';
import { useProfileActions } from '@profile';
import { useFinanceComponents } from '@finance';

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const getDepositResource = useFinanceActions('getDepositResource');
const deposits = getDepositResource();

const {
    DateFilter,
    ExportXlsx
} = useFinanceComponents();


if (!profile.state.finances) {
    profile.state.finances = {
        filters: {},
        perPage: 25,
        expanded: []
    };
} else if (!profile.state.finances.expanded) {
    profile.state.finances.expanded = [];
}

const filters = reactive({});

Object.assign(filters, profile.state.finances.filters);
deposits.setFilters(filters);

const isChanged = computed(() => {
    return !_.isEqual(filters, deposits.filters);
});

function filtrate() {
    Object.keys(filters).forEach(key => {
        if (!Object.keys(filters[key]).length) {
            delete filters[key];
        }
    });
    deposits.setFilters(filters);
    deposits.load();

    profile.state.finances.filters = filters;
    profile.saveState();
}

function resetFilters() {
    Object.keys(filters).forEach(key => {
        delete filters[key];
    });
    Object.assign(filters, deposits.filters);
}

const transactionsData = ref([])

const transactionColumns = computed(() => {
    return formatTransactions(tab.value === deposits.state);
});

const formatAgent = (user) => {
    return user ? user.name : 'Unknown';
};

function getTransactionColumns(value) {
    transactionsData.value = transactionColumns.value || []
}

const formatTransactions = (transactions) => {
    return transactions.map(transaction => {
        const commonData = {
            lead_id: transaction.lead && transaction.lead.id,
            lead_name: formatLeadName(transaction.lead),
            agent: formatAgent(transaction.user),
            amount: parseFloat(transaction.amount) / parseFloat(transaction.rate),
            currency: transaction.currency,
            rate: parseFloat(transaction.rate),
            usdt_amount: parseFloat(transaction.amount),
            method: transaction.method,
            type: transaction.type,
            created_at: transaction.created_at
        };

        if (tab.value === 'Replenishments') {
            return {
                ...commonData,
                sub_method: transaction.details ? transaction.details.card_name : '',
                is_ftd: transaction.lead && transaction.lead.is_ftd
            };
        }

        return commonData;
    });
};

const formatLeadName = (lead) => {
    return lead ? `${lead.first_name} ${lead.last_name}` : 'Unknown';
};

function getFilterValueByKey(filter, key) {
    if (deposits.filterset[filter]) {
        key = String(key).split(',').pop();
        const filterset = deposits.filterset[filter].find(item => item.id === Number(key) || item.value == key);
        let value = filterset ? filterset.key || filterset.value || filterset.name : key || 'Null';

        return value;
    }

    return key;
}

function dropFilter(filter, value) {
    delete filters[filter][value];
    if (!Object.keys(filters[filter]).length) {
        delete filters[filter];
    }
    deposits.dropFilterValue(filter, value);
    deposits.load(null, {}, 'Drop Filter');

    profile.state.finances.filters = deposits.filters;
    profile.saveState();
}

function resetAllFilters() {
    Object.keys(deposits.filters).forEach(filter => {
        delete filters[filter];
        Object.keys(deposits.filters[filter]).forEach(key => {
            deposits.dropFilterValue(filter, key);
        })
    });

    deposits.setFilters(deposits.filters);
    deposits.load(null, {}, 'Reset Filters');

    profile.state.finances.filters = {};
    profile.saveState();
}

function isExpanded(section) {
    return computed({
        get: () => (profile.state.finances.expanded || []).includes(section),
        set: value => {
            value
                ? profile.state.finances.expanded.push(section)
                : profile.state.finances.expanded.splice(profile.state.finances.expanded.indexOf(section), 1);
            profile.saveState();
            return true
        }
    })
}

const isTestExt = isExpanded('is_test');
const isFtdExt = isExpanded('is_ftd');
const typeExt = isExpanded('type');
const statusExt = isExpanded('status');
const methodExt = isExpanded('method');
const affiliatesExt = isExpanded('affiliates');
const teamsExt = isExpanded('teams');
</script>


<template>
    <g-flex class="tools" gap="5" align="center">
        <date-filter/>
        <export-xlsx v-on:export-click="getTransactionColumns" v-bind:data="transactionsData"/>
    </g-flex>

    <g-flex class="filters" align="end" gap="4" wrap="wrap">

        <fieldset class="filter" v-for="(values, key) in deposits.filters" v-bind:key="key" v-bind:class="key">
            <legend>{{ $t(`filters.${key}`, key) }}:</legend>

            <div class="filter-item" v-for="(value, i) in Object.keys(values)" v-bind:key="`${key}-${i}`">
                <div class="value">{{ getFilterValueByKey(key, value) }}</div>
                <g-symbol name="close" width="14" height="14" v-on:click="dropFilter(key, value)"/>
            </div>
        </fieldset>

        <fieldset v-if="Object.keys(deposits.filters).length > 0" class="filter reset-all">
            <div class="filter-item" v-on:click="resetAllFilters">
                <div class="value">Reset All</div>
                <g-symbol name="close" width="14" height="14"/>
            </div>
        </fieldset>
    </g-flex>

    <div>
        <!-- FTD -->
        <g-filterset v-model="filters.is_test"
                    v-model:expanded="isTestExt"
                    v-bind:label="$t('filters.is_test', 'Is Test')"
                    value-field="value"
                    v-bind:filterset="deposits.filterset.is_test"/>

        <!-- FTD -->
        <g-filterset v-model="filters.is_ftd"
                    v-model:expanded="isFtdExt"
                    v-bind:label="$t('filters.is_ftd', 'Is FTD')"
                    value-field="value"
                    v-bind:filterset="deposits.filterset.is_ftd"/>

        <!-- TYPE -->
        <g-filterset v-model="filters.type"
                    v-model:expanded="typeExt"
                    v-bind:label="$t('filters.type', 'Type')"
                    value-field="key"
                    v-bind:filterset="deposits.filterset.type"/>

        <!-- STATUS -->
        <g-filterset v-model="filters.status"
                    v-model:expanded="statusExt"
                    v-bind:label="$t('filters.status', 'Status')"
                    value-field="key"
                    v-bind:filterset="deposits.filterset.status"/>

        <!-- METHOD -->
	    <g-filterset v-model="filters.method"
                    v-model:expanded="methodExt"
	                v-bind:label="$t('filters.method', 'Method')"
                    value-field="key"
	                v-bind:filterset="deposits.filterset.method"/>

        <!-- AFFILIATE -->
	    <g-filterset v-model="filters.affiliates"
                    v-model:expanded="affiliatesExt"
	                v-bind:label="$t('filters.affiliates', 'Affiliates')"
	                value-field="id"
	                v-bind:filterset="deposits.filterset.affiliates"
                    is-search/>

        <!-- TEAMS -->
        <g-filterset v-model="filters.teams"
                    v-model:expanded="teamsExt"
                    v-bind:label="$t('filters.teams', 'Teams')"
                    value-field="id"
                    v-bind:filterset="deposits.filterset.teams"/>

        <transition>
            <g-flex v-if="isChanged" class="btn-bar" gap="5">
                <g-button class="reset-btn" v-on:click="resetFilters">Reset</g-button>
                <g-button class="apply-btn" v-on:click="filtrate">Apply</g-button>
            </g-flex>
        </transition>
    </div>
</template>

<style lang="scss" scoped>
.tools {
    z-index: 2;
    top: 0;
    position: sticky;
    padding: 4px 5px 8px;
    border-radius: 0 0 5px 5px;
    margin: -20px -15px 0 -10px;
    box-shadow: 0 5px 15px -5px #00000055;
    background-color: var(--secondary, $secondary);
}

.filters {
    padding: 10px 5px;
}

.filter {
    gap: 4px;
    flex-flow: wrap;
    padding: 4px 0;
    font-size: 0.8rem;
    border-radius: 5px;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    color: var(--env-text-color, $env-text-color);

    & > legend {
        text-transform: capitalize;
    }

    & > .filter-item {
        border-radius: 5px;
        padding: 0 4px 1px 8px;
        display: inline-flex;
        align-items: center;
        gap: 2px;
        height: 28px;
        color: white;
        box-shadow: 0 5px 15px -10px #000000aa;

        &:first-child {
            margin-left: 5px;
        }

        & > .g-symbol {
            cursor: pointer;
            fill: var(--btn-primary-color, $btn-primary-color);
        }

        & > .value {
            font-weight: 500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 60px;
        }
    }

    &.reset-all > .filter-item {
        cursor: pointer;
        background-color: black;
    }

    &.is_ftd > .filter-item {
        background-color: #eaa308;
    }

    &.created_at > .filter-item {
        background-color: var(--primary-hover, $primary-hover);

        & > .value {
            max-width: min-content;
        }
    }

    &.type > .filter-item {
        background-color: var(--secondary, $secondary);
    }

    &.status > .filter-item {
        background-color: var(--success, $success);
    }

    &.method > .filter-item {
        background-color: #b010df;
    }

    &.affiliates > .filter-item {
        background-color: #6050ef;

        & > .value {
            max-width: 80px;
        }
    }

    &:not(.is_ftd, .created_at, .type, .status, .method, .affiliates, .reset-all) > .filter-item {
        background-color: var(--danger-hover, $danger-hover);
    }
}

.g-filterset {
    margin: 20px 0;
}

.btn-bar {
    z-index: 3;
    margin: 0 -30px 0 -25px;
    text-align: right;
    padding: 8px 15px;
    bottom: 0;
    position: sticky;
    border-radius: 9px 9px 0 0;
    background-color: var(--env-bg, $env-bg);
    box-shadow: 0 -5px 15px -5px #00000033;

    & > .apply-btn,
    & > .reset-btn {
        width: 50%;
        flex-grow: 1;
        background-color: transparent;
        border: 1px solid var(--separator-color, $separator-color);
        color: var(--env-text-color, $env-text-color);

        &:hover {
            color: white;
            background-color: var(--primary-hover, $primary-hover);
        }
    }

    & > .reset-btn {
        background-color: transparent;
        border: 1px solid var(--separator-color, $separator-color);
        color: var(--env-text-color, $env-text-color);

        &:hover {
            color: white;
            background-color: var(--primary-hover, $primary-hover);
        }
    }

    & > .apply-btn {
        color: white;
        background-color: var(--primary, $primary);
        border: 1px solid var(--primary, $primary);

        &:hover {
            background-color: var(--primary-hover, $primary-hover);
        }
    }
}

.v-enter-active,
.v-leave-active {
    transition: bottom 0.4s;
}

.v-enter-from,
.v-leave-to {
    bottom: -100px;
}
</style>