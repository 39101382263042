<script setup>

import { computed, ref, defineProps, defineEmits } from 'vue';
import { useCampaignActions } from '@campaign';
import { useOfferActions } from '@offer';
import { useBuyerActions } from '@buyer';
import { useUserActions } from '@user';
import { useTeamActions } from '@team';
import { useCountryActions } from '@country';
import { useProfileActions } from '@profile';

const props = defineProps(['modelValue', 'errors']);
const emit = defineEmits(['update:modelValue']);

const getCampaignResource = useCampaignActions('getCampaignResource');
const campaign = getCampaignResource();

const getOfferResource = useOfferActions('getOfferResource');
const offers = getOfferResource();

const getBuyerResource = useBuyerActions('getBuyerResource');
const buyerResource = getBuyerResource();

const getCountryResource = useCountryActions('getCountryResource');
const countryResource = getCountryResource({ is_disabled: [0] });

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const getTeamResource = useTeamActions('getTeamResource');
const teams = getTeamResource();

const getAffiliates = useUserActions('getAffiliates');

const languages = ref(['EN', 'RU', 'PL']);
const affiliates = ref([]);
const { modelValue, errors = [] } = props;

const showAllCountries = ref(false);
const countries = computed(() => {
    return showAllCountries.value
        ? countryResource.state
        : countryResource.state.filter(country => country.is_favorite || props.modelValue.geo.includes(country.code));
});

const buyers = computed(() => {
    return buyerResource.state.filter(buyer => buyer.user_id === props.modelValue.affiliate_id);
});

getAffiliates().then(response => {
    affiliates.value = response.data;
});

function affiliateChoice(id) {
    if (buyers.value.length === 1) {
        buyerChoice(buyers.value[0].id);
    } else {
        buyers.index = null;
        props.modelValue.buyer_id = null;
    }
}

function buyerChoice(id) {
    buyerResource.index = buyerResource.state.findIndex(buyer => buyer.id === id);
    props.modelValue.buyer_id = buyerResource.model.id;
    props.modelValue.type = buyerResource.model.type;
    props.modelValue.cost = buyerResource.model.cost;
}
</script>

<template>
    <form class="make-campaign campaign-form" v-on:submit.prevent>
        <g-flex justify="between" align="end" wrap>
            <fieldset>
                <legend>{{ $t('campaign.campaign_name', 'Campaign Name') }}</legend>
                <g-input v-model="modelValue.name" v-bind:readonly="profile.cant('Campaign Edit')" v-bind:error="errors.name">
                    <g-symbol v-if="profile.cant('Campaign Edit')" name="lock" width="18" height="18"/>
                </g-input>
            </fieldset>

            <fieldset>
                <legend>{{ $t('campaign.alias', 'Alias') }}</legend>
                <g-input v-model="modelValue.alias" v-bind:readonly="profile.cant('Campaign Edit')">
                    <g-symbol v-if="profile.cant('Campaign Edit')" name="lock" width="18" height="18"/>
                </g-input>
            </fieldset>

            <fieldset>
                <legend>{{ $t('campaign.offer', 'Offer') }}</legend>
                <g-select v-model="modelValue.offer_id"
                          v-bind:options="offers.state"
                          option-text="name"
                          option-value="id"
                          v-bind:readonly="profile.cant('Campaign Edit')"/>
            </fieldset>

            <fieldset>
                <legend>{{ $t('base.language', 'Language') }}</legend>
                <g-combobox v-model="modelValue.language"
                            v-bind:options="languages"
                            v-bind:disabled="profile.cant('Campaign Edit')"/>
            </fieldset>

            <fieldset>
                <legend>{{ $t('campaign.source', 'Source') }}</legend>
                <g-input v-model="modelValue.source" v-bind:readonly="profile.cant('Campaign Edit')">
                    <g-symbol v-if="profile.cant('Campaign Edit')" name="lock" width="18" height="18"/>
                </g-input>
            </fieldset>

            <fieldset>
                <legend>{{ $t('campaign.assign_team', 'Assign team') }}</legend>
                <g-select v-model="modelValue.target_team"
                          v-bind:options="teams.state"
                          option-text="name"
                          option-value="id"
                          v-bind:readonly="profile.cant('Campaign Edit')"/>
            </fieldset>
        </g-flex>

        <fieldset class="geo">
            <legend>{{ $t('campaign.geo') }}</legend>
            <g-flex direction="column" align="center">
                <div class="countries">
                    <g-checkbox v-model="modelValue.geo"
                                v-for="country in countries"
                                v-bind:key="`country-${country.code}`"
                                v-bind:value="country.code"
                                v-bind:disabled="profile.cant('Campaign Edit')">
                        {{ $t(`countries.${country.code}`) }}
                    </g-checkbox>
                </div>

                <button type="button"
                        class="show-countries"
                        v-bind:class="{hidden: showAllCountries}"
                        v-on:click="showAllCountries = !showAllCountries">
                    {{ showAllCountries ? $t('countries.hide_fav', 'Hide not favorite Countries') : $t('countries.show_all', 'Show all Countries') }}
                </button>
            </g-flex>
        </fieldset>

        <g-flex justify="between" align="end" wrap>
            <fieldset>
                <legend>{{ $t('campaign.affiliate', 'Affiliate') }}</legend>
                <g-select v-model="modelValue.affiliate_id"
                          v-bind:options="affiliates"
                          option-text="name"
                          option-value="id"
                          v-on:select="affiliateChoice"
                          v-bind:readonly="profile.cant('Campaign Edit')"/>
            </fieldset>

            <fieldset>
                <legend>{{ $t('campaign.buyer', 'Media Buyer') }}</legend>
                <g-select v-model="modelValue.buyer_id"
                          v-bind:options="buyers"
                          option-text="name"
                          option-value="id"
                          v-on:select="buyerChoice"
                          v-bind:readonly="profile.cant('Campaign Edit')"/>
            </fieldset>

            <fieldset>
                <legend>{{ $t('campaign.type', 'Type') }}</legend>
                <g-select v-model="modelValue.type"
                          v-bind:options="[{value: 'CPA'}, {value: 'CPL'}]"
                          option-text="value"
                          option-value="value"
                          v-bind:readonly="profile.cant('Campaign Edit')"/>
            </fieldset>

            <fieldset>
                <legend>{{ $t('campaign.cost', 'Cost') }}</legend>
                <g-input v-model="modelValue.cost" v-bind:readonly="profile.cant('Campaign Edit')">
                    <g-symbol v-if="profile.cant('Campaign Edit')" name="lock" width="18" height="18"/>
                </g-input>
            </fieldset>
        </g-flex>
    </form>
</template>

<style scoped lang="scss">
.make-campaign {
    container-type: inline-size;
}

fieldset {
    width: 49%;

    & > .g-input,
    & > .g-select:deep(.field),
    & > .g-combobox:deep(.field) {
        background-color: var(--body-bg, $body-bg);
    }
}

.geo {
    width: 100%;
    margin: 10px 0;
    padding: 10px 20px 0;
    border-radius: var(--bar-rounding, $bar-rounding);
    background-color: var(--body-bg, $body-bg);

    .countries {
        column-count: 3;
        padding-bottom: 5px;
        flex-grow: 1;
        height: 180px;
        overflow-y: auto;

        & > .g-checkbox {
            display: inline-block;
            overflow: hidden;
            width: 100%;
            max-width: 120px;
            white-space: nowrap;
        }
    }
}

.show-countries {
    font-size: 0.9rem;
    background-color: transparent;

    &:not(.hidden) {
        color: var(--success-hover, $success-hover);

        &::after {
            content: ' \2193';
        }
    }

    &.hidden {
        color: var(--danger-hover, $danger-hover);

        &::after {
            content: ' \2A2F';
        }
    }
}
</style>